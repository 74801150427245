<template>
  <div>
    <div v-if="business">
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
      <div class="main-container">
        <v-container fluid class="screen-width fill-height">
          <v-row class="pa-0 ma-0">
            <v-col cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="business.cover.original"
                    :lazy-src="business.cover.loading"
                    aspect-ratio="1"
                    class="grey lighten-2 cover-up"
                    max-width="100%"
                    max-height="250"
                    @click="selectImage('banner')"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip style="width: 20%" class="mb-5 img-tag">
                        Cover
                      </v-chip>
                    </v-row>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        >
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
                <span>Cambiar imágen de cover</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="business.logo.original"
                    :lazy-src="business.logo.loading"
                    aspect-ratio="1"
                    class="grey lighten-2 logo-up"
                    max-width="150"
                    max-height="150"
                    @click="selectImage('brand')"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip class="mb-1 img-tag"> Logo </v-chip>
                    </v-row>

                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        >
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
                <span>Cambiar imágen de logo</span>
              </v-tooltip>

              <v-row justify="end" v-if="isAllowed">
                <v-col v-if="tab == 'ocho-pay'" cols="12" sm="2">
                  <v-btn @click="qrDialog = true" block color="primary">
                    Generar QR
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu offset-y right rounded="xl">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        block
                        :loading="imageDownloadLoading"
                        @click="exit = !exit"
                      >
                        Descargar
                        <v-icon right dark v-if="!exit">
                          fas fa-chevron-down
                        </v-icon>
                        <v-icon right dark v-else> fas fa-chevron-up </v-icon>
                      </v-btn>
                    </template>
                    <v-list rounded>
                      <v-list-item-group color="primary">
                        <v-list-item v-if="business.logo.original">
                          <v-list-item-title>
                            <a @click.prevent="downloadImage(business, 'logo')">
                              Logo
                            </a>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if="business.cover.original">
                          <v-list-item-title>
                            <a
                              @click.prevent="downloadImage(business, 'cover')"
                            >
                              Cover
                            </a>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

              <v-tabs class="mt-10" v-model="tab">
                <v-tab href="#general" key="general"> General </v-tab>
                <v-tab v-if="isAllowed" href="#km" key="km"
                  >Cargos extras y KM</v-tab
                >
                <v-tab v-if="isAllowed" href="#qr-menu" key="qr-menu">
                  Menú HN
                </v-tab>

                <v-tab v-if="isAllowed" href="#state" key="state">
                  Estados del Comercio
                </v-tab>
              </v-tabs>

              <v-card
                class="text-left flex-grow-1"
                :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
                elevation="0"
                style="border-radius: 17px !important; overflow: hidden"
              >
                <v-card-text class="text--primary">
                  <v-tabs-items v-if="!loading" v-model="tab">
                    <v-tab-item value="general" key="general">
                      <business-general
                        :business="business"
                        ref="general"
                        :isAllowed="isAllowed"
                        :cities="cities"
                        :save="save"
                        :categories="categories"
                      />
                    </v-tab-item>

                    <v-tab-item value="km" key="km">
                      <business-km
                        :business="business"
                        :cities="cities"
                        :save="save"
                        :categories="categories"
                      />
                    </v-tab-item>

                    <v-tab-item value="qr-menu" key="qr-menu">
                      <qr-options
                        ref="qrOptions"
                        :business="business"
                        :cities="cities"
                        type="qr"
                        :save="save"
                        :categories="categories"
                      />
                    </v-tab-item>

                    <v-tab-item value="state" key="state">
                      <business-status
                        :business="business"
                        :cities="cities"
                        :save="save"
                        :categories="categories"
                      />
                    </v-tab-item>
                    <!-- <v-tab-item value="api" key="api">
                      <api
                        :business="business"
                        :cities="cities"
                        :save="save"
                        :isAllowed="isAllowed"
                        :categories="categories"
                      />
                    </v-tab-item> -->
                  </v-tabs-items>

                  <v-container fluid>
                    <v-row class="pa-0 ma-0">
                      <v-col cols="12" sm="6" class="mt-1">
                        <v-switch
                          v-if="isAllowed && tab == 'general'"
                          v-model="business.closed"
                          :loading="save"
                          label="Cerrado por hoy"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-row justify="end">
                          <v-btn
                            class="save-btn"
                            width="170px"
                            min-height="45px"
                            @click="updateBusinessInformation()"
                            color="primary"
                            :loading="save"
                            dark
                          >
                            Guardar
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <v-dialog v-model="modalUploadImage" v-if="imgSrc" max-width="610px">
      <!-- <v-overlay class="loading-center" :value="save">
        <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay> -->

      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">
            Vista previa imágen de
            {{ imageType === "banner" ? "portada" : "logo" }}
          </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalUploadImage = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="imgSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="imgSrc" />
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                :loading="save"
                @click.prevent="saveImage"
                class="mt-4 save-btn"
                color="primary"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="qrDialog" v-if="qrDialog" max-width="60%">
      <generate-qr
        @cancel="qrDialog = false"
        :url="`https://pay.ocho.life/process/transaction/${business.qrCode}`"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import axios from "axios";
import ledgerMixin from "@/mixins/ledgerMixin";
import qrOptions from "./tabs/qrConfiguration.vue";
import businessStatus from "./tabs/states.vue";
import businessGeneral from "./tabs/general.vue";
// import api from "./tabs/api";
import businessKm from "./tabs/km.vue";
import ochoPay from "./tabs/pay.vue";
import generateQr from "@/components/generateQR";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";

export default {
  name: "general-configuration",
  components: {
    qrOptions,
    businessStatus,
    businessGeneral,
    businessKm,
    ochoPay,
    generateQr,
    lottie: Lottie,
    // api,
  },
  mixins: [ledgerMixin],
  data() {
    return {
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modal: null,
      business: null,
      snackbar: false,
      snackbarText: null,
      color: "green",
      timeout: 3000,
      modalUploadImage: false,
      categories: [],
      cities: [],
      loading: true,
      save: false,
      imageType: null,
      suscriptionBusiness: null,
      imgSrc: "",
      imageFile: null,
      imageDownloadLoading: false,
      exit: false,
      tab: null,
      qrDialog: false,
    };
  },
  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
  },
  watch: {
    selectedBusiness() {
      if (this.suscriptionBusiness) {
        this.suscriptionBusiness();
      }
      // this.business = null;
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),
    isAllowed() {
      let allowedRoles = ["sudo", "admin", "EFCM", "AC", "AF"];
      return allowedRoles.includes(this.user.type);
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    successUpload() {
      this.modalUploadImage = false;
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Imágen actualizada";
    },

    selectImage(imageType) {
      this.imageType = imageType;

      if (this.isAllowed) {
        this.$refs.input.click();
      } else {
        this.snackbarText =
          "Este usuario no tiene permiso para actualizar imagen del comercio..";
        this.snackbar = true;
      }
    },
    saveImage() {
      this.uploading = true;

      let bucket =
        this.imageType == "brand" || this.imageType == "banner"
          ? `gs://${process.env.VUE_APP_PROJECT_ID}-businesses`
          : null;

      if (bucket) {
        this.save = true;
        fb.app()
          .storage(bucket)
          .ref()
          .child(`${this.selectedBusiness[".key"]}/${this.imageType}`)
          .put(this.imageFile)
          .then(() => {
            this.successUpload();
          })
          .catch(() => {
            this.save = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
      }
    },
    closeModalUploadImage() {
      this.modalUploadImage = false;
    },
    updateCascadeBusiness(businessId, business) {
      db.collection(`cities/${business.cities[0]}/sections`)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionId = doc.id;
            let section = doc.data();

            let items = section.target.filter((doc) => {
              return doc.business == businessId;
            });

            items.forEach((elementUpdate) => {
              elementUpdate.isBusinessActive = business.active;
              elementUpdate.closed = business.closed;
              elementUpdate.isForceClosed = business.isForceClosed;
              elementUpdate.isComingSoon = business.isComingSoon;
            });

            db.collection(`cities/${business.cities[0]}/sections`)
              .doc(sectionId)
              .update({
                target: section.target,
              })
              .catch((error) => {
                // throw error;
              });
          });
        })
        .catch(console.error);
    },
    async updateBusinessInformation() {
      if (this.business.discount > 1 || this.business.discount < 0) {
        this.snackbarText =
          "El porcentaje de comisión debe ser un valor entre 0 y 1.";
        this.snackbar = true;
        return;
      }
      let businessInfo = {
        shortName: this.business.shortName ? this.business.shortName : "",
        tin: this.business.tin ? this.business.tin : "",
        zone: this.business.zone ? this.business.zone : "",
        contactName: this.business.contactName ? this.business.contactName : "",
        isForceClosed: this.business.hasOwnProperty("isForceClosed")
          ? this.business.isForceClosed
          : false,
        closed: this.business.hasOwnProperty("closed")
          ? this.business.closed
          : false,
        description: this.business.description ? this.business.description : "",
        phone: this.business.phone ? this.business.phone : "",
        businessPhone: this.business.businessPhone
          ? this.business.businessPhone
          : "",

        city: this.business.city ? this.business.city : "",
        cities: this.business.cities ? this.business.cities : [],

        keywords: this.business.keywords ? this.business.keywords : [],
        ochoCategories: this.business.ochoCategories
          ? this.business.ochoCategories
          : [],
        promoSectionTitle: this.business.promoSectionTitle
          ? this.business.promoSectionTitle
          : "",
        promoSectionSubtitle: this.business.promoSectionSubtitle
          ? this.business.promoSectionSubtitle
          : "",
        qrClosed: this.business.hasOwnProperty("qrClosed")
          ? this.business.qrClosed
          : false,
        qrBackgroundType: this.business.hasOwnProperty("qrBackgroundType")
          ? this.business.qrBackgroundType
          : false,
        qrBackgroundColor: this.business.qrBackgroundColor || "",
        qrThemeColor: this.business.qrThemeColor || "",
        qrIsVip: this.business.qrIsVip || false,
        qrRequiredOtp: this.business.qrRequiredOtp || false,
        email: this.business.email || "",
      };
      if (this.user.showPageCine) {
        businessInfo.allowBurnFromApp = this.business.allowBurnFromApp || false;
        businessInfo.dateValidation = this.business.dateValidation || false;
        businessInfo.timeBeforeMovie = this.business.timeBeforeMovie || 500;
      }
      if (this.isAllowed) {
        businessInfo.freeShippingSectionTitle =
          this.business.freeShippingSectionTitle || "";

        businessInfo.freeShippingSectionSubtitle =
          this.business.freeShippingSectionSubtitle || "";

        businessInfo.discount = this.business.discount
          ? this.business.discount
          : 0;

        businessInfo.timeZone = this.business.timeZone
          ? this.business.timeZone
          : "";

        businessInfo.deliveryType = this.business.deliveryType
          ? this.business.deliveryType
          : "";

        businessInfo.freeShippingMinimum = this.business.freeShippingMinimum
          ? this.business.freeShippingMinimum
          : 0;

        businessInfo.tags = this.$refs.general
          ? this.$refs.general.businessTags
          : [];

        businessInfo.kmConfiguration = this.business.kmConfiguration;

        businessInfo.qrKmConfiguration = this.business.qrKmConfiguration
          ? this.business.qrKmConfiguration
          : {
              dayCostKm: 0,
              dropoffDay: 0,
              pickupDay: 0,
              extraKmCharge: 0,
              maxDistance: 5,
            };

        businessInfo.kmConfiguration.dayCostKm = this.business.kmConfiguration
          .dayCostKm
          ? this.business.kmConfiguration.dayCostKm
          : 0;

        businessInfo.kmConfiguration.dropoffDay = this.business.kmConfiguration
          .dropoffDay
          ? this.business.kmConfiguration.dropoffDay
          : 0;

        businessInfo.kmConfiguration.pickupDay = this.business.kmConfiguration
          .pickupDay
          ? this.business.kmConfiguration.pickupDay
          : 0;

        businessInfo.kmConfiguration.extraKmCharge = this.business
          .kmConfiguration.extraKmCharge
          ? this.business.kmConfiguration.extraKmCharge
          : 0;

        businessInfo.priceRange = this.business.priceRange
          ? this.business.priceRange
          : "";

        businessInfo.processTime = this.business.processTime
          ? this.business.processTime
          : "";

        businessInfo.takeOutCost = this.business.takeOutCost
          ? this.business.takeOutCost
          : 0;

        businessInfo.fleetType = this.business.fleetType
          ? this.business.fleetType
          : "";

        businessInfo.switchLayout = this.business.switchLayout || false;

        businessInfo.methodCharge = this.business.methodCharge
          ? this.business.methodCharge
          : "";
        businessInfo.methodValidate = this.business.methodValidate
          ? this.business.methodValidate
          : "";
        businessInfo.minimumSale = this.business.minimumSale
          ? this.business.minimumSale
          : "";
        businessInfo.type = this.business.type ? this.business.type : "";
        businessInfo.slogan = this.business.slogan ? this.business.slogan : "";
        businessInfo.extraFees =
          this.business.extraFees.length > 0 ? this.business.extraFees : [];

        businessInfo.active = this.business.active || false;
        businessInfo.adults = this.business.adults || false;
        businessInfo.closed = this.business.closed || false;
        businessInfo.featured = this.business.featured || false;
        businessInfo.isComingSoon = this.business.isComingSoon || false;
        businessInfo.isForceClosed = this.business.isForceClosed || false;
        businessInfo.isOpen = this.business.isOpen || false;
        businessInfo.noContractWithOcho =
          this.business.noContractWithOcho || false;
        businessInfo.ochoPayTax = this.business.ochoPayTax || false;
        businessInfo.isTestMode = this.business.isTestMode || false;
        businessInfo.isWarehouse = this.business.isWarehouse || false;
        businessInfo.kmApply = this.business.kmApply || false;
        businessInfo.manageEvents = this.business.manageEvents || false;
        businessInfo.manageInventory = this.business.manageInventory || false;
        businessInfo.ochoActive = this.business.ochoActive || false;
        businessInfo.standAlone = this.business.standAlone || false;
        businessInfo.switchLayout = this.business.switchLayout || false;
        businessInfo.reservations = this.business.reservations || false;
        businessInfo.ochoCategoryKm = this.business.ochoCategoryKm || false;
        businessInfo.ochoVerified = this.business.ochoVerified || false;
        businessInfo.isCongested = this.business.isCongested || false;

        businessInfo.qrActive = this.business.qrActive || false;
        businessInfo.qrTypesOrdersPanel =
          this.business.qrTypesOrdersPanel || [];
        businessInfo.qrCode = this.business.qrCode || "";
        businessInfo.qrPercentage = Number(this.business.qrPercentage) || 0;
        businessInfo.qrCity = this.business.qrCity || "";
        businessInfo.qrCities = this.business.qrCities || [];
        businessInfo.qrCategory = this.business.qrCategory || "";
        businessInfo.qrDeliveryType = this.business.qrDeliveryType || "";
        businessInfo.qrDeliveryActive = this.business.qrDeliveryActive || false;
        businessInfo.qrPickupActive = this.business.qrPickupActive || false;
        businessInfo.qrDineInActive = this.business.qrDineInActive || false;
        businessInfo.qrShowOutofStock = this.business.qrShowOutofStock || false;
        businessInfo.qrIsFather = this.business.qrIsFather || false;
        businessInfo.qrFatherTypeLayout =
          this.business.qrFatherTypeLayout || "";
        businessInfo.qrIsChildOf = this.business.qrIsChildOf || "";
        businessInfo.qrFleetType = this.business.qrFleetType || "ocho";
        businessInfo.qrFleetFee = this.business.qrFleetFee || 0;
        businessInfo.qrOchoPayPercentage =
          this.business.qrOchoPayPercentage || 0;

        businessInfo.executiveId = this.business.executiveId
          ? this.business.executiveId
          : "";

        businessInfo.qrDeliveryActiveByOcho =
          this.business.qrDeliveryActiveByOcho || false;

        businessInfo.qrExtraFee = this.business.qrExtraFee
          ? Number(this.business.qrExtraFee)
          : 0;

        businessInfo.cityFleet = this.business.cityFleet || false;
        businessInfo.fleetTeamId = this.business.fleetTeamId || "";
        businessInfo.ownFleetFee = this.business.ownFleetFee || "";
        businessInfo.ochoPayActive = this.business.ochoPayActive || false;

        businessInfo.thirdPartyUpdate = this.business.thirdPartyUpdate || false;
        businessInfo.thirdPartyProvider =
          this.business.thirdPartyProvider || "";
        businessInfo.thirdPartyCode = this.business.thirdPartyCode || null;
        businessInfo.hideFreeShippingSection =
          this.business.hideFreeShippingSection || false;
      }

      this.save = true;

      let prevValue = this.selectedBusiness;

      if (this.$refs.qrOptions && this.$refs.qrOptions.opl) {
        await db
          .collection("businesseBillingSetup")
          .doc(this.selectedBusiness[".key"])
          .set({
            opl: this.$refs.qrOptions.opl,
          });
      }
      var businessRef = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"]);

      businessRef
        .update(businessInfo)
        .then(() => {
          if (
            this.business.isLobbySection == true ||
            this.business.isLobbySection == false
          ) {
            this.updateCascadeBusiness(
              this.selectedBusiness[".key"],
              this.business
            );
          }
          this.save = false;
          this.snackbar = true;
          this.snackbarText = "Información actualizada correctamente.";

          this.createLedger({
            source_id: this.selectedBusiness[".key"],
            source_type: "businesses",
            currentValue: this.selectedBusiness,
            prevValue: prevValue,
          });
        })
        .catch((error) => {
          console.debug(error);
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente";
        });
    },
    async getBusinessData() {
      this.suscriptionBusiness = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .onSnapshot(async (doc) => {
          if (doc.exists) {
            this.business = doc.data();

            let queryGetCities = this.$binding(
              "cities",
              db.collection("cities").where("deleted", "==", false)
            ).then((cities) => {
              this.cities = this.cities.map((item) => {
                return {
                  value: item[".key"],
                  text: item.name,
                };
              });
            });

            await Promise.all([queryGetCities]);

            this.$binding(
              "categories",
              db.collection("categories").where("deleted", "==", false)
            ).then(() => {
              this.categories = this.categories.map((item) => {
                return {
                  value: item[".key"],
                  text: item.name,
                  city: item.city,
                  tags: item.tags,
                };
              });
              this.loading = false;
            });
            // } else {
            //   this.loading = false;
            // }
          }
        });
    },
    downloadImage(business, type = "logo") {
      this.imageDownloadLoading = true;
      let setImage =
        type === "logo" ? business.logo.original : business.cover.original;
      axios
        .get(setImage, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${business.shortName.trim()}-${type}`;
          link.click();
          URL.revokeObjectURL(link.href);
          this.imageDownloadLoading = false;
        })
        .catch(console.error);
      this.exit = false;
    },
  },
  destroyed() {
    if (this.suscriptionBusiness) {
      this.suscriptionBusiness();
    }
  },
};
</script>

<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}

.save-btn {
  margin-top: 20px;
}

.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}

.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}

.btn-modal-add-tags {
  justify-content: center;
}

.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.upload {
  display: none;
}

.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}

.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}

.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}

.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}

.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 1px solid #80808080;
  box-shadow: 0 0 0 0;
}

/* h1 {
  color: #f06f3d!important;
} */
</style>
