<template>
  <v-container fluid class="fill-height">
    <v-row v-if="!isAllowed && !ownRoles.includes(user.type)" class="pa-0 ma-0">
      <v-col cols="12" md="6">
        <h3 class="p-0">
          {{ business.shortName }}
        </h3>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="p-0">
          {{ business.tin ? business.tin : "RTN no agregado" }}
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="isAllowed" class="pa-0 ma-0 pt-2 mt-2">
      <v-col cols="12" md="6">
        <h3>Nombre del comercio</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Ingrese el nombre del comercio"
          v-model="business.shortName"
        />
      </v-col>
      <v-col cols="12" md="6">
        <h3>RTN</h3>
        <input
          class="control-input control-input-number"
          @keydown="isNumber($event)"
          type="number"
          placeholder="Ingrese el RTN"
          v-model="business.tin"
        />
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 pt-2 mt-2">
      <v-col cols="12" md="6">
        <h3>Persona de contacto</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Persona a contactar en el negocio"
          v-model="business.contactName"
        />
      </v-col>
      <v-col cols="12" md="6">
        <h3>Teléfono del contacto</h3>
        <input
          class="control-input"
          placeholder="Número de teléfono del contacto"
          v-model="business.phone"
        />
      </v-col>
      <v-col cols="12" md="6">
        <h3>Teléfono del comercio</h3>
        <input
          class="control-input"
          placeholder="Número de teléfono del comercio"
          v-model="business.businessPhone"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 pt-2 mt-2" v-if="user.showPageCine">
      <v-col cols="12" md="6">
        <h3>Validar horario de entrada a la sala</h3>
        <v-switch v-model="business.dateValidation"> </v-switch>
      </v-col>
      <v-col cols="12" md="6" v-if="business.dateValidationCine">
        <h3>Minutos antes de la pelicula</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Tiempo en minutos"
          @keypress="isNumber($event, business.timeBeforeMovie)"
          v-model="business.timeBeforeMovie"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 pt-2 mt-2" v-if="user.showPageCine">
      <v-col cols="12" md="6">
        <h3>Permitir anular desde la app</h3>
        <v-switch v-model="business.allowBurnFromApp"> </v-switch>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 pt-2 mt-2" v-if="user.showPageCine">
      <v-col cols="12" md="6">
        <h3>Validar horario de entrada a la sala</h3>
        <v-switch v-model="business.dateValidation"> </v-switch>
      </v-col>
      <v-col cols="12" md="6" v-if="business.dateValidationCine">
        <h3>Minutos antes de la pelicula</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Tiempo en minutos"
          @keypress="isNumber($event, business.timeBeforeMovie)"
          v-model="business.timeBeforeMovie"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 pt-2 mt-2" v-if="user.showPageCine">
      <v-col cols="12" md="6">
        <h3>Permitir anular desde la app</h3>
        <v-switch v-model="business.allowBurnFromApp"> </v-switch>
      </v-col>
    </v-row>

    <v-row v-if="isAllowed" class="pa-0 ma-0 pt-4 mt-4">
      <v-col cols="12" md="6">
        <h3>Dirección</h3>
        <textarea
          v-model="business.zone"
          placeholder="Dirección exacta del negocio"
          class="control-input"
          rows="3"
        >
        </textarea>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Descripción</h3>
        <textarea
          @keypress="maxLength($event, business.description, 300)"
          maxlength="300"
          placeholder="Descripción corta del negocio"
          v-model="business.description"
          class="control-input"
          rows="3"
        >
        </textarea>
      </v-col>
    </v-row>

    <v-row v-if="isAllowed">
      <v-col cols="12" md="6">
        <h3>Título sección envío gratis</h3>
        <input
          class="control-input"
          type="text"
          v-model="business.freeShippingSectionTitle"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Subtítulo sección envío gratis</h3>
        <input
          class="control-input"
          type="text"
          v-model="business.freeShippingSectionSubtitle"
        />
      </v-col>
    </v-row>

    <v-row v-if="isAllowed" class="pa-0 ma-0 pt-4 mt-4">
      <v-col cols="12" md="6">
        <h3>Título de la sección de descuento</h3>
        <input
          class="control-input"
          placeholder="Título de la sección de descuento"
          v-model="business.promoSectionTitle"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Sub-título de la sección de descuento</h3>
        <input
          class="control-input"
          placeholder="Sub-título de la sección de descuento"
          v-model="business.promoSectionSubtitle"
        />
      </v-col>
    </v-row>

    <v-row v-if="isAllowed" class="pa-0 ma-0 pt-4 mt-4">
      <v-col cols="12" sm="12" md="12">
        <h3>Eslogan</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Ingrese el eslogan de la empresa"
          v-model="business.slogan"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <h3>Correo electrónico</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Ingrese el correo electrónico de la empresa"
          v-model="business.email"
        />
      </v-col>

      <v-col cols="12">
        <h3>Ciudades</h3>
        <v-autocomplete
          v-model="business.cities"
          :items="cities"
          outlined
          multiple
          chips
          rounded
          color="primary"
          clearable
          class="mt-5"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip v-bind="attrs" :input-value="selected" @click="select">
              <strong>{{ item.text }}</strong>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12">
        <h3>Categorias</h3>
        <v-autocomplete
          v-model="business.ochoCategories"
          :items="getCategories"
          multiple
          outlined
          chips
          rounded
          color="primary"
          clearable
          class="mt-5"
          item-text="fullName"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              @click="select"
              close
              @click:close="removeCategory(item)"
            >
              <strong>{{ item.text }}</strong>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12">
        <h3>Tags</h3>
        <v-autocomplete
          v-model="businessTags"
          :items="allTags"
          multiple
          outlined
          chips
          item-text="name"
          item-value=".key"
          :loading="gettingTags"
          clearable
          rounded
          class="mt-5"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
            >
              <strong>{{ item.name }}</strong>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12">
        <h3>Keywords</h3>
        <v-combobox
          v-model="business.keywords"
          :search-input.sync="comboboxSearch"
          hide-selected
          label="Palabras clave para la busqueda"
          multiple
          rounded
          outlined
          chips
          deletable-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Enter <kbd>enter</kbd> pagar agregar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-row class="ml-1 mt-4">
      <v-col
        v-if="isAllowed || ownRoles.includes(user.type)"
        cols="12"
        xs="6"
        sm="6"
        md="6"
      >
        <h3>Comisión</h3>
        <v-text-field
          @keypress="isNumber($event, business.discount)"
          placeholder="Comisión"
          rounded
          outlined
          class="mt-4"
          :rules="[rules.min]"
          hint="El porcentaje de comisión debe ser un número entre 0 y 1"
          v-model.number="business.discount"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Monto mínimo para envío gratis</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event, business.freeShippingMinimum)"
          type="number"
          placeholder="Monto mínimo para envío gratis"
          v-model.number="business.freeShippingMinimum"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Tipo de delivery</h3>
        <v-autocomplete
          v-model="business.deliveryType"
          :items="deliveryType"
          placeholder="Seleccione tipo de delivery"
          outlined
          item-text="name"
          item-value="value"
          rounded
          class="mt-5"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Tipo de flota</h3>

        <v-autocomplete
          v-model="business.fleetType"
          :items="fleetType"
          placeholder="Seleccione tipo de flota"
          outlined
          item-text="name"
          item-value="value"
          rounded
          class="mt-5"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Código de Flota</h3>
        <input
          class="control-input"
          placeholder="Código de Flota"
          v-model="business.fleetTeamId"
        />
      </v-col>

      <!-- <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Método de carga</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Ingrese el método de carga"
          v-model="business.methodCharge"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Método de validación</h3>
        <input
          class="control-input"
          type="text"
          placeholder="Método de validación"
          v-model="business.methodValidate"
        />
      </v-col> -->

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Venta mínima</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event, business.minimumSale)"
          type="number"
          placeholder="Venta mínima"
          v-model.number="business.minimumSale"
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Tipo de pantalla</h3>
        <v-autocomplete
          v-model="business.type"
          :items="types"
          placeholder="Seleccione tipo de pantalla"
          outlined
          item-text="name"
          item-value="value"
          rounded
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Zona Horario</h3>
        <v-autocomplete
          v-model="business.timeZone"
          :items="timeZone"
          placeholder="Seleccione la zona horaria"
          outlined
          rounded
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" xs="6" sm="6" md="6">
        <h3>Ejecutivo encargado</h3>
        <v-autocomplete
          v-model="business.executiveId"
          :items="getExecutives"
          placeholder="Ejecutivo titular"
          outlined
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
    </v-row>

    <!-- <v-row class="pa-0 ma-0 mt-2">
      <v-col cols="12" sm="12">
        <h2 style="color: #FF1744">
          Actualización automática con proveedores de terceros
        </h2>
        <v-divider></v-divider>
      </v-col>
      <v-col v-if="isAllowed" cols="12" sm="5" md="5">
        <h3 class="mt-2">Proveedor de terceros</h3>
        <v-autocomplete
          v-model="business.thirdPartyProvider"
          :items="thirdPartyProviders"
          placeholder="Seleccione el proveedor de terceros"
          outlined
          item-text="text"
          item-value="value"
          rounded
        />
      </v-col>

      <v-col v-if="isAllowed" cols="12" sm="5" md="5">
        <h3>Código del comercio</h3>
        <input
          class="control-input control-input-number"
          placeholder="Ingrese el código"
          v-model="business.thirdPartyCode"
        />
      </v-col>

      <v-col v-if="isAllowed" class="mt-7" cols="12" md="2">
        <v-switch
          v-model="business.thirdPartyUpdate"
          :loading="save"
          label="Activar"
        ></v-switch>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import viewImage from "@/components/viewImage";
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  name: "business-general",
  props: ["business", "cities", "categories", "save", "isAllowed"],
  components: {
    viewImage,
  },
  data() {
    return {
      comboboxSearch: null,
      ownRoles: ["ACC"],
      executives: [],
      city: {},
      tags: [],
      thirdPartyProviders: [
        {
          text: "Farsimán",
          value: "siman",
        },
        {
          text: "Economascotas",
          value: "economascotas",
        },
        {
          text: "Simán Mini Market",
          value: "simanMarket",
        },
        {
          text: "Simán Supermercado",
          value: "simanSuperMarket",
        },
      ],
      rules: {
        min: (v) =>
          (v && v >= 0 && v <= 1) ||
          "El porcentaje de comisión debe ser un número entre 0 y 1.",
      },
      deliveryType: [
        { name: "Ninguno", value: "none" },
        { name: "Regular", value: "regular" },
        { name: "Recogida", value: "pickUp" },
        { name: "Recogida regular", value: "regularPickUp" },
      ],
      fleetType: [
        { name: "Ocho", value: "ocho" },
        { name: "Mixto", value: "mixed" },
        { name: "Propia", value: "own" },
      ],
      types: [
        { name: "Airtime", value: "airTime" },
        { name: "ATM", value: "atm" },
        { name: "Store", value: "store" },
        { name: "Wallet", value: "wallet" },
      ],
      timeZone: [
        "-1200",
        "-1100",
        "-1000",
        "-900",
        "-800",
        "-700",
        "-600",
        "-500",
        "-400",
        "-300",
        "-200",
        "-100",
        "0",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
        "1100",
        "1200",
      ],
      loading: false,
      gettingTags: true,
    };
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),

    getExecutives() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.executives.sort(compare);
    },

    getCategories() {
      let categories = this.categories.filter(
        (item) =>
          this.business.cities && this.business.cities.includes(item.city)
      );
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities.find((city) => city.value === category.city);
          newCategory.fullName = city ? `${city.text} - ${category.text}` : "";
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
    allTags() {
      let tags = [];

      if (this.business.ochoCategories) {
        this.business.ochoCategories.forEach((category) => {
          let categoryTags = this.tags.filter(
            (tag) =>
              tag.categories &&
              tag.categories.length &&
              tag.categories.includes(category)
          );

          tags = [...tags, ...categoryTags];
        });
      }

      // this.filterTags();

      return tags.sort(this.compareArray);
    },

    businessTags: {
      get: function () {
        return this.business.tags && this.business.tags.length
          ? this.business.tags.filter((tag) =>
              this.allTags.find((item) => item[".key"] == tag)
            )
          : [];
      },
      // setter
      set: function (newValue) {
        this.business.tags = newValue;
      },
    },
  },

  methods: {
    filterTags() {
      this.business.tags =
        this.business.tags && this.business.tags.length
          ? this.business.tags.filter((tag) =>
              this.allTags.find((item) => item[".key"] == tag)
            )
          : [];
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    maxLength(evt, field, length) {
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
    remove(item) {
      this.business.tags.splice(this.allTags.indexOf(item), 1);
      this.business.tags = [...this.business.tags];
    },
    removeCategory(category) {
      this.business.ochoCategories = this.business.ochoCategories.filter(
        (item) => item != category.value
      );
    },
    async getTags() {
      let city = this.selectedBusiness.cities[0];

      await this.$binding("city", db.collection("cities").doc(city));

      await this.$binding(
        "tags",
        db
          .collection("countries")
          .doc(this.city.countryId)
          .collection("tags")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );

      this.gettingTags = false;
    },
  },

  async mounted() {
    this.getTags();

    await this.$binding(
      "executives",
      db
        .collection("appUsers")
        .where("type", "==", "AC")
        .where("active", "==", true)
    );
  },
};
</script>

