<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" md="4">
        <h3>Código</h3>
        <input class="control-input" type="text" v-model="business.qrCode" />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Color de fondo</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrBackgroundColor"
        />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Color de tema</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrThemeColor"
        />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Porcentaje</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          v-model="business.qrPercentage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { db, fb } from "@/firebase";

export default {
  name: "ocho-pay",
  props: ["business", "save"],
  components: {},
  data() {
    return {
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),
  },

  methods: {},

  async mounted() {},
};
</script>
