<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Costo por Km recorrido</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event)"
          type="number"
          placeholder="Ingrese el costo por Km recorrido"
          v-model.number="business.kmConfiguration.dayCostKm"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Cargo extra por KM</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event)"
          type="number"
          placeholder="Ingrese el cargo extra por KM"
          v-model.number="business.kmConfiguration.extraKmCharge"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Costo por recoger</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event)"
          type="number"
          placeholder="Ingrese el Costo por recoger"
          v-model.number="business.kmConfiguration.dropoffDay"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Costo por entregar</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event)"
          type="number"
          placeholder="Ingrese el costo por entregar"
          v-model.number="business.kmConfiguration.pickupDay"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h3>Distancia Máxima (KM)</h3>
        <input
          class="control-input control-input-number"
          @keypress="isNumber($event)"
          type="number"
          placeholder="Distancia Máxima (KM)"
          v-model.number="business.kmConfiguration.maxDistance"
        />
      </v-col>

      <v-row v-if="extraFees.length > 0 && !loading" class="pa-0 ma-0 mt-3">
        <v-col cols="12">
          <h3>Tarifas adicionales</h3>
          <v-divider class="mb-5"></v-divider>
        </v-col>

        <v-row class="fee ml-3" v-for="(fee, index) of extraFees" :key="index">
          <v-col cols="12" md="4">
            <h3>Tipo de recargo</h3>
            <v-autocomplete
              v-model="fee.extraFeeIsFixed"
              :items="extraFeeIsFixed"
              placeholder="Seleccione tipo de recargo"
              outlined
              rounded
              class="mt-5"
            />
          </v-col>

          <v-col cols="12" md="4">
            <h3>Nombre del recargo</h3>
            <input
              class="control-input"
              type="text"
              placeholder="Nombre del recargo"
              v-model="fee.extraFeeName"
            />
          </v-col>

          <v-col cols="12" md="4">
            <h3>Valor del recargo</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event, fee.extraFeeSum)"
              type="number"
              placeholder="Valor del recargo"
              v-model.number="fee.extraFeeSum"
            />
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <v-switch v-model="fee.applyToDelivery" label="Aplica a delivery">
            </v-switch>
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <v-switch v-model="fee.applyToPickUp" label="Aplica a PickUp">
            </v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch v-model="fee.active" label="Activa"></v-switch>
          </v-col>
          <v-col cols="2">
            <v-btn
              @click="removeFee(index)"
              small
              block
              color="red darken-4"
              class="white--text mt-5"
            >
              Eliminar
            </v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12">
          <a @click="addFees()">Agregar tarifa</a>
        </v-col>
      </v-row>

      <v-col cols="12" md="4">
        <h3>Precio</h3>
        <v-autocomplete
          v-model="business.priceRange"
          :items="priceRange"
          placeholder="Seleccione el rango de precio"
          outlined
          rounded
          class="mt-5"
        />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Tiempo promedio</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          placeholder="Ingrese el tiempo promedio"
          v-model.number="business.processTime"
        />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Costo por empaque</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          placeholder="Ingrese el costo por empaque"
          v-model.number="business.takeOutCost"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import viewImage from "@/components/viewImage";

export default {
  name: "business-km",
  props: ["business", "categories", "cities", "save"],
  components: {
    viewImage,
  },
  data() {
    return {
      comboboxSearch: null,
      loading: true,
      gettingTags: true,
      imageQRFile: "",
      qrImageDialog: false,
      qrSrc: "",
      defaultImage:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      deliveryType: [
        { name: "Ninguno", value: "none" },
        { name: "Regular", value: "regular" },
        { name: "Recogida", value: "pickUp" },
        { name: "Recogida regular", value: "regularPickUp" },
      ],
      fleetType: [
        { name: "Ocho", value: "ocho" },
        { name: "Mixto", value: "mixed" },
        { name: "Propia", value: "own" },
      ],
      priceRange: [1, 2, 3, 4],
      types: [
        { name: "Airtime", value: "airTime" },
        { name: "ATM", value: "atm" },
        { name: "Store", value: "store" },
        { name: "Wallet", value: "wallet" },
      ],
      timeZone: [
        "-1200",
        "-1100",
        "-1000",
        "-900",
        "-800",
        "-700",
        "-600",
        "-500",
        "-400",
        "-300",
        "-200",
        "-100",
        "0",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
        "1100",
        "1200",
      ],
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
      qrDelvieryFees: [{ name: "", cost: 80 }],
    };
  },

  watch: {
    extraFees() {
      this.business.extraFees = this.extraFees;
    },
    "business.cities"() {
      let filteredCategories = [];
      this.categories.forEach((category) => {
        if (
          this.business.ochoCategories.includes(category.value) &&
          this.business.cities.includes(category.city)
        ) {
          filteredCategories.push(category.value);
        }
      });
      this.business.ochoCategories = filteredCategories;
    },
  },
  methods: {
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },

    showFilePicker() {
      this.$refs.file.click();
    },

    setQrImage(e) {
      this.imageQRFile = e.target.files[0];

      if (this.imageQRFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.qrSrc = event.target.result;
          // this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageQRFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },

    addQRFees() {
      this.qrDelvieryFees.push({
        name: "",
        cost: 80,
      });
    },

    removeFee(index) {
      this.extraFees = this.extraFees.filter((item, i) => i != index);
      if (this.extraFees.length == 0) {
        this.extraFees = [
          {
            extraFeeIsFixed: "",
            extraFeeName: "",
            extraFeeSum: "",
            active: false,
          },
        ];
      }
    },

    removeQRFee(index) {
      this.qrDelvieryFees = this.qrDelvieryFees.filter((item, i) => i != index);
    },
    remove(item) {
      this.business.tags.splice(this.allTags.indexOf(item), 1);
      this.business.tags = [...this.business.tags];
    },
    removeCategory(category) {
      this.business.ochoCategories = this.business.ochoCategories.filter(
        (item) => item != category.value
      );
    },
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    compare(a, b) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    },
    compareArray(a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
    async getTags() {
      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i];
        let tags = await db
          .collection("categories")
          .doc(category.value)
          .collection("tags")
          .where("deleted", "==", false)
          .get();

        category.tags = tags.docs.map((item) => {
          return {
            text: item.data().name,
            value: item.id,
          };
        });
      }
      this.gettingTags = false;
    },
  },
  computed: {
    allTags() {
      let tags = [];
      for (let index = 0; index < this.categories.length; index++) {
        if (
          this.business.ochoCategories.includes(this.categories[index].value)
        ) {
          tags = tags.concat(this.categories[index].tags);
        }
      }
      return tags.sort(this.compareArray);
    },

    businessTags: {
      get: function () {
        return this.business.tags.length
          ? this.business.tags.filter((tag) =>
              this.allTags.find((item) => item.value == tag)
            ) //this.allTags.includes(tag))
          : [];
      },
      // setter
      set: function (newValue) {
        this.business.tags = newValue;
      },
    },
    getCategories() {
      let categories = this.categories.filter(
        (item) =>
          this.business.cities && this.business.cities.includes(item.city)
      );
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities.find((city) => city.value === category.city);
          newCategory.fullName = city ? `${city.text} - ${category.text}` : "";
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
  },
  async mounted() {
    this.cities = this.cities.sort(this.compare);

    this.getTags();

    this.qrDelvieryFees =
      this.business.qrDelvieryFees && this.business.qrDelvieryFees.length
        ? this.business.qrDelvieryFees
        : this.qrDelvieryFees;

    if (this.business.extraFees && this.business.extraFees.length > 0) {
      this.extraFees = Object.assign([], this.business.extraFees);
    } else {
      this.business.extraFees = this.extraFees;
    }

    if (!this.business.hasOwnProperty("isTestMode")) {
      this.business.isTestMode = false;
    }

    this.loading = false;
  },
};
</script>



<style scoped>
.fee {
  background-color: #e6e6e680;
  border: 1px solid #ffffff7a;
  /* border-radius: 5%; */
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.fa-expand-alt {
  font-size: 25px;
  color: #ffffff;
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  background-color: #b1b1b1a6;
  padding: 5px;
}
</style>
