<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.active"
          :loading="save"
          label="Activo"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.adults"
          :loading="save"
          label="Adultos"
        ></v-switch>
      </v-col>

      <v-col cols="12" md="3">
        <v-switch
          v-model="business.closed"
          :loading="save"
          label="Cerrado por hoy"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.featured"
          :loading="save"
          label="Destacado"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isComingSoon"
          :loading="save"
          label="Próximamente"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isForceClosed"
          :loading="save"
          label="Cerrado permanente"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isOpen"
          :loading="save"
          label="Abierto"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isTestMode"
          :loading="save"
          label="Modo tester"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.noContractWithOcho"
          :loading="save"
          label="El comercio no tiene contrato con Ocho"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.ochoPayTax"
          :loading="save"
          label="Ocho absorbe ISV"
        ></v-switch>
      </v-col>

      <!-- <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isWarehouse"
          :loading="save"
          label="Es almacén"
        ></v-switch>
      </v-col> -->

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.kmApply"
          :loading="save"
          label="Configuración de kilometros"
        ></v-switch>
      </v-col>

      <!-- <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.manageEvents"
          :loading="save"
          label="Administra eventos"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.manageInventory"
          :loading="save"
          label="Administra inventarios"
        ></v-switch>
      </v-col> -->

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.ochoActive"
          :loading="save"
          label="Activo por ocho"
        ></v-switch>
      </v-col>

      <!-- <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.standAlone"
          :loading="save"
          label="Independiente"
        ></v-switch>
      </v-col> -->

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.switchLayout"
          :loading="save"
          label="Layout vertical"
        ></v-switch>
      </v-col>

      <!-- <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.reservations"
          :loading="save"
          label="Reservaciones"
        ></v-switch>
      </v-col> -->

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.ochoCategoryKm"
          :loading="save"
          label="Ocho categorías Km"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.ochoVerified"
          :loading="save"
          label="Verificado por ocho"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.isCongested"
          :loading="save"
          label="Comercio congestionado"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.cityFleet"
          :loading="save"
          label="Usar flota por defecto"
        ></v-switch>
      </v-col>

      <v-col v-if="isAllowed" cols="12" md="3">
        <v-switch
          v-model="business.hideFreeShippingSection"
          :loading="save"
          label="Ocultar sección de envíos gratis"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "business-status",
  props: ["business", "save"],
  computed: {
    ...mapState(["selectedBusiness", "user"]),

    isAllowed() {
      let roles = ["admin", "sudo", "AC", "EFCM", "AF"];
      return roles.includes(this.user.type) ? true : false;
    },
  },
};
</script>

