<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <h2 style="color: #FF1744">Configuración menu</h2>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="4">
        <h3>Código</h3>
        <input class="control-input" type="text" v-model="business.qrCode" />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Color de fondo</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrBackgroundColor"
        />
      </v-col>

      <v-col cols="12" md="4">
        <h3>Color de tema</h3>
        <input
          class="control-input"
          type="color"
          style="height: 40px"
          v-model="business.qrThemeColor"
        />
      </v-col>

      <v-col v-if="type == 'qr'" cols="12" md="4">
        <h3>Porcentaje de Comisión</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          v-model.number="business.qrPercentage"
        />
      </v-col>

      <v-col v-if="type == 'pay'" cols="12" md="4">
        <h3>Porcentaje de Comisión</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          v-model.number="business.qrOchoPayPercentage"
        />
      </v-col>

      <!-- <v-col cols="12" md="4">
        <h3>Cargo extra</h3>
        <input
          @keypress="isNumber($event)"
          class="control-input control-input-number"
          type="number"
          v-model.number="business.qrExtraFee"
        />
      </v-col> -->
      <!-- <v-col v-if="type == 'qr'" cols="12" md="4">
        <h3>Ciudades</h3>
        <v-autocomplete
          v-model="business.qrCities"
          :items="cities"
          outlined
          multiple
          rounded
          color="primary"
          clearable
          class="mt-5"
        />
      </v-col> -->
      <v-col v-if="type == 'qr'" cols="12" md="4">
        <h3>Ciudad</h3>
        <v-autocomplete
          v-model="business.qrCity"
          :items="cities"
          outlined
          rounded
          color="primary"
          clearable
          class="mt-5"
        />
      </v-col>
      <v-col v-if="type == 'qr'" cols="12" md="4">
        <h3>Ciudad</h3>
        <v-autocomplete
          v-model="business.qrCity"
          :items="cities"
          outlined
          rounded
          color="primary"
          clearable
          class="mt-5"
        />
      </v-col>

      <v-col v-if="type == 'qr'" cols="12" md="4">
        <h3>Categoría</h3>
        <v-autocomplete
          v-model="business.qrCategory"
          :items="getCategories"
          outlined
          rounded
          color="primary"
          clearable
          class="mt-5"
          item-text="fullName"
        />
      </v-col>

      <!-- <v-col v-if="type == 'qr'" cols="12" sm="4" md="4">
        <h3>Tipo de delivery</h3>
        <v-autocomplete
          v-model="business.qrDeliveryType"
          :items="deliveryType"
          placeholder="Seleccione tipo de delivery para QR"
          outlined
          item-text="name"
          item-value="value"
          rounded
          class="mt-5"
        />
      </v-col> -->
      <v-col v-if="type == 'qr'" cols="12" sm="8" md="8">
        <h3>Métodos de entrega</h3>
        <v-autocomplete
          v-model="business.qrTypesOrdersPanel"
          :items="typeOrder"
          placeholder="Seleccione tipo de entrega para QR"
          outlined
          multiple
          item-text="text"
          item-value="value"
          rounded
          class="mt-5"
        />
      </v-col>

      <v-col v-if="type == 'qr'" cols="12" sm="4" md="4">
        <h3>Tipo de Flota</h3>
        <v-autocomplete
          v-model="business.qrFleetType"
          :items="fleetType"
          placeholder="Seleccione tipo de flota para delivery"
          outlined
          item-text="name"
          item-value="value"
          rounded
          class="mt-5"
        />
      </v-col>

      <v-col
        v-if="business.qrFleetType == 'own' && type == 'qr'"
        cols="12"
        sm="4"
        md="4"
      >
        <h3>Id de la Flota</h3>
        <input
          @keypress="isNumber"
          class="control-input control-input-number"
          type="number"
          v-model.number="business.ownFleetFee"
        />
      </v-col>

      <v-col
        v-if="business.qrFleetType == 'own' && type == 'qr'"
        cols="12"
        sm="4"
        md="4"
      >
        <h3>Comisión por delivery</h3>
        <input
          @keypress="isNumber"
          class="control-input control-input-number"
          type="number"
          v-model.number="business.qrFleetFee"
        />
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-row v-if="business.qrKmConfiguration">
          <v-col cols="12" sm="12" md="12">
            <h2 style="color: #FF1744">Configuración de Kilómetraje</h2>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="6">
            <h3>Costo por recoger</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event)"
              type="number"
              placeholder="Ingrese el Costo por recoger"
              v-model.number="business.qrKmConfiguration.dropoffDay"
            />
          </v-col>

          <v-col cols="12" md="6">
            <h3>Costo por entregar</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event)"
              type="number"
              placeholder="Ingrese el costo por entregar"
              v-model.number="business.qrKmConfiguration.pickupDay"
            />
          </v-col>
          <v-col cols="12" md="6">
            <h3>Costo por Km recorrido</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event)"
              type="number"
              placeholder="Ingrese el costo por Km recorrido"
              v-model.number="business.qrKmConfiguration.dayCostKm"
            />
          </v-col>

      
          <v-col cols="12" md="6">
            <h3>Distancia máxima Km a costo normal</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event)"
              type="number"
              placeholder="Ingrese el costo por entregar"
              v-model.number="business.qrKmConfiguration.maxDistance"
            />
          </v-col>
          <v-col cols="12" md="6">
            <h3>Costo por Km arriba del límite</h3>
            <input
              class="control-input control-input-number"
              @keypress="isNumber($event)"
              type="number"
              placeholder="Ingrese el cargo extra por KM"
              v-model.number="business.qrKmConfiguration.extraKmCharge"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <h2 style="color: #FF1744">Configuración de Ocho Pay</h2>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" sm="5" md="5">
            <h3>OPL Api Key</h3>
            <input class="control-input" type="text" v-model="opl.key" />
          </v-col>
          <v-col cols="12" sm="5" md="5">
            <h3>OPL ID del comercio</h3>
            <input class="control-input" type="text" v-model="opl.businessId" />
          </v-col>
          <v-col class="mt-9" cols="12" sm="2" md="2">
            <v-switch class="mt-3" v-model="opl.active" label="Activar">
            </v-switch>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" md="4">
            <h3>Imagen de fondo del menú</h3>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" style="position: relative; cursor: pointer">
                  <i
                    v-if="business.qrBackgroundImage"
                    @click="qrImageDialog = true"
                    class="fas fa-expand-alt"
                  ></i>
                  <v-img
                    class="mt-4"
                    max-height="200px"
                    @click="showFilePicker"
                    :src="business.qrBackgroundImage || defaultImage"
                  >
                  </v-img>
                </div>
              </template>
              <span>Clic para Cambiar</span>
            </v-tooltip>
          </v-col>

          <input
            @change="setQrImage"
            ref="file"
            type="file"
            style="display: none"
            accept="image/*"
          />
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-row>
          <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrActive"
              label="Activar QR"
            ></v-switch>
          </v-col>

          <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrClosed"
              label="Cerrar QR"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrBackgroundType"
              label="Usar imagen de fondo"
            ></v-switch>
          </v-col>

          <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrDeliveryActiveByOcho"
              label="Delivery Activo por Ocho"
            ></v-switch>
          </v-col>

          <!-- <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrDeliveryActive"
              label="Delivery Activo"
            ></v-switch>
          </v-col> -->

          <!-- <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrPickupActive"
              label="PickUp Activo"
            ></v-switch>
          </v-col> -->

          <!-- <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrDineInActive"
              label="Comer en restaurante"
            ></v-switch>
          </v-col> -->

          <v-col v-if="type == 'qr'" cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrShowOutofStock"
              label="Mostrar productos agotados"
            ></v-switch>
          </v-col>
        </v-row>
        <!-- qrRequiredOtp -->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <h2 style="color: #FF1744">Configuración agrupador de menus</h2>

            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
              :loading="save"
              v-model="business.qrIsFather"
              label="Es Padre"
            ></v-switch
          ></v-col>
          <v-col cols="12" md="6" v-if="business.qrIsFather">
            <label>Tipo de agrupador</label>
            <v-autocomplete
              v-model="business.qrFatherTypeLayout"
              :items="TypeLayout"
              placeholder="Tipo de agrupador"
              outlined
              item-text="text"
              item-value="value"
              rounded
            />
          </v-col>

          <v-col cols="12" sm="12" md="6" v-if="!business.qrIsFather">
            <label>Comercio Padre</label>
            <v-autocomplete
              :items="sortedArrayByName"
              v-model="business.qrIsChildOf"
              placeholder="Seleccione el comercio padre"
              dense
              outlined
              rounded
              messages="Si es comercio Hijo"
              class="m-0 p-0"
              style="border-radius: 50px"
              item-value="value"
            >
              <template v-slot:selection="{ item }">
                <label class="ma-0 pa-0">
                  {{ item.text }}
                  <small
                    class="font-weight-bold"
                    v-if="item.cities"
                    style="color: #FF1744"
                    >({{ item.cities }})</small
                  >
                </label>
              </template>

              <template v-slot:item="data">
                <div class="mb-1 mt-1 fle">
                  <v-avatar v-if="data.item.logo" left>
                    <v-img
                      :lazy-src="data.item.logo.loading"
                      :src="data.item.logo.original"
                    ></v-img>
                  </v-avatar>
                  <span class="ml-3">
                    {{ data.item.text }}
                  </span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="12">
            <h2 style="color: #FF1744">Configuración menu VIP</h2>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrIsVip"
              label="Activar listas VIP"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="3">
            <v-switch
              :loading="save"
              v-model="business.qrRequiredOtp"
              label="Código de verificación OTP requerido"
            ></v-switch>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <v-dialog width="900px" v-model="qrImageDialog">
      <viewImage
        :url="business.qrBackgroundImage"
        @cancel="qrImageDialog = false"
      ></viewImage>
    </v-dialog>

    <v-dialog
      persistent
      v-model="modalUploadQr"
      v-if="modalUploadQr"
      max-width="610px"
    >
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline"> Vista previa </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalUploadQr = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="qrSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="qrSrc" />
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                @click.prevent="saveImage"
                :loading="uploading"
                class="mt-4 save-btn"
                color="primary"
                dark
              >
                Subir
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import viewImage from "@/components/viewImage";
import { mapState } from "vuex";
import { db, fb } from "@/firebase";

export default {
  name: "qr-options",
  props: ["business", "cities", "categories", "save", "type"],
  components: {
    viewImage,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      gettingTags: true,
      imageQRFile: "",
      qrImageDialog: false,
      allBusiness: [],
      modalUploadQr: false,
      fleetType: [
        { name: "Ocho", value: "ocho" },
        { name: "Propia", value: "own" },
      ],
      qrSrc: "",
      uploading: false,
      defaultImage:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",

      deliveryType: [
        { name: "Ninguno", value: "none" },
        { name: "Regular", value: "regular" },
        { name: "Recogida", value: "pickUp" },
        { name: "Recogida regular", value: "regularPickUp" },
      ],
      typeOrder: [
        { value: "regular", text: "Delivery" },
        { value: "dineIn", text: "Servir en mesa" },
        { value: "pickUp", text: "Recogida" },
        { value: "room", text: "Servir en la habitación" },
      ],
      TypeLayout: [
        { value: "directorybyCity", text: "Directorio por Ciudad" },
        { value: "businessDirectory", text: "Directorio de comercios" },
        { value: "directoryForOrder", text: "Directorio para ordenar" },
      ],
      opl: {
        key: "",
        secret: "",
        active: false,
      },
      fac: {},
    };
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),

    getCategories() {
      let categories = this.categories.filter(
        (item) => this.business.qrCity && this.business.qrCity == item.city
      );
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities.find((city) => city.value === category.city);
          newCategory.fullName = city ? `${city.text} - ${category.text}` : "";
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
    sortedArrayByName: function () {
      function compare(a, b) {
        if (a.text && b.text && a.text < b.text) return -1;
        if (a.text && b.text && a.text > b.text) return 1;
        return 0;
      }
      return this.businessesList.sort(compare);
    },
    businessesList() {
      return this.allBusiness.map((item) => {
        return {
          text: item.shortName,
          value: item[".key"],
          logo: item.logo,
        };
      });
    },
  },

  methods: {
    compare(a, b) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    },
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },
    saveImage() {
      this.uploading = true;

      let extension = this.imageQRFile.type.split("/")[1];

      var uploadTask = fb
        .app()
        .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-businesses`)
        .ref()
        .child(`${this.selectedBusiness[".key"]}/qr.${extension}`)
        .put(this.imageQRFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.uploading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await db
              .collection("businesses")
              .doc(this.selectedBusiness[".key"])
              .update({
                qrBackgroundImage: downloadURL,
              });

            this.uploading = false;
            this.modalUploadQr = false;
          });
        }
      );
    },

    showFilePicker() {
      this.$refs.file.click();
    },

    setQrImage(e) {
      this.imageQRFile = e.target.files[0];

      if (this.imageQRFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.qrSrc = event.target.result;
          this.modalUploadQr = true;
        };

        reader.readAsDataURL(this.imageQRFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },

    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getAllBusinesses() {
      await this.$binding(
        "allBusiness",
        db
          .collection("businesses")
          .where("qrIsFather", "==", true)
          .where("deleted", "==", false)
      );
    },
  },

  async mounted() {
    if (!this.business.qrKmConfiguration) {
      this.business.qrKmConfiguration = {
        dayCostKm: 0,
        dropoffDay: 0,
        pickupDay: 0,
        extraKmCharge: 0,
        maxDistance: 5,
      };
    }

    this.cities = this.cities.sort(this.compare);

    let billingSetup = await db
      .collection("businesseBillingSetup")
      .doc(this.selectedBusiness[".key"])
      .get();

    billingSetup = billingSetup.data();

    if (billingSetup && billingSetup.opl) {
      this.opl = billingSetup.opl;
    }
    this.getAllBusinesses();
  },
};
</script>


<style scoped>
.fee {
  background-color: #e6e6e680;
  border: 1px solid #ffffff7a;
  /* border-radius: 5%; */
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.fa-expand-alt {
  font-size: 25px;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  background-color: #b1b1b1a6;
  padding: 5px;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}
</style>
