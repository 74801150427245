<template>
  <div style="position: relative">
    <i @click="$emit('cancel')" class="fas fa-times"></i>
    <img :src="url" />
  </div>
</template>
<script>
export default {
  name: "view-image",
  props: ["url"],
  //   components: {},
  //   data() {
  //     return {};
  //   },
};
</script>

<style scoped>
.fa-times {
  font-size: 30px;
  color: #ffffff;
  z-index: 100;
  position: absolute;
  right: 2%;
  top: 2%;
  font-weight: bold;
  cursor: pointer;
}

img {
  width: 100%;
  /* height: 500px; */
  max-height: 90vh;
  object-fit: cover;
}
</style>

